[class*="mhc-icon-"], [class^=mhc-icon-]{position: relative; display:inline-block
	&:before{content:''; display:block; position:relative; width:20px; height:20px}
	
}


@font-face {
      font-family: 'mhc-icon';
      src: url('../fonts/mhc-icon.eot');
      src: url('../fonts/mhc-icon.eot#iefix') format('embedded-opentype'),
           url('../fonts/mhc-icon.woff') format('woff'),
           url('../fonts/mhc-icon.ttf') format('truetype'),
           url('../fonts/mhc-icon.svg#mhc-icon') format('svg');
      font-weight: normal;
      font-style: normal;
    }
     
     
.mhc-icon{font-family: "mhc-icon"; font-style: normal; font-weight: normal; speak: never; display: inline-block; text-decoration: inherit; font-size:1rem; text-align: center;
      font-variant: normal; text-transform: none; line-height: 1em;
      -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
}










.icon-user-_1_:before { content: '\e800'; } /* '' */
.icon-plus:before { content: '\e801'; } /* '' */
.icon-minus:before { content: '\e802'; } /* '' */
.icon-search-icon:before { content: '\e803'; } /* '' */
.icon-location:before { content: '\e804'; } /* '' */
.icon-right:before { content: '\e805'; } /* '' */
.icon-left:before { content: '\e806'; } /* '' */
.icon-up:before { content: '\e807'; } /* '' */
.icon-down:before { content: '\e808'; } /* '' */
.icon-left-open-big:before { content: '\e809'; } /* '' */
.icon-right-open-big:before { content: '\e80a'; } /* '' */
.icon-up-open-big:before { content: '\e80b'; } /* '' */
.icon-exchange:before { content: '\e80c'; } /* '' */
.icon-log-out:before { content: '\e80d'; } /* '' */
.icon-dot:before { content: '\e80e'; } /* '' */
.icon-calendar:before { content: '\e80f'; } /* '' */
.icon-mhc-info:before { content: '\e810'; } /* '' */
.icon-dot-1:before { content: '\e811'; } /* '' */
.icon-verticalline:before { content: '\e812'; } /* '' */
.icon-trash-empty:before { content: '\e813'; } /* '' */
.icon-order-medicines:before { content: '\e814'; } /* '' */
.icon-book-tests:before { content: '\e815'; } /* '' */
.icon-book-consult:before { content: '\e816'; } /* '' */
.icon-icon-feather-trash-2:before { content: '\e817'; } /* '' */
.icon-care-packages:before { content: '\e818'; } /* '' */
.icon-pills:before { content: '\e819'; } /* '' */
.icon-cancel:before { content: '\e81a'; } /* '' */
.icon-dashboard:before { content: '\e81b'; } /* '' */
.icon-icon-phone:before { content: '\e81c'; } /* '' */
.icon-pencil:before { content: '\e81d'; } /* '' */
.icon-camera:before { content: '\e81e'; } /* '' */
.icon-next:before { content: '\e81f'; } /* '' */
.icon-refresh:before { content: '\e820'; } /* '' */
.icon-patientmydocuments:before { content: '\e821'; } /* '' */
.icon-patientmyprescriptions:before { content: '\e822'; } /* '' */
.icon-patientmybookings:before { content: '\e823'; } /* '' */
.icon-patientmyreports:before { content: '\e824'; } /* '' */
.icon-star-empty:before { content: '\e825'; } /* '' */
.icon-star:before { content: '\e826'; } /* '' */
.icon-phone-circled:before { content: '\e827'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */










